import { useInfiniteQuery, useQuery, UseQueryOptions } from 'react-query';

import { Api } from './api';
import {
  IAddressResponse,
  IAddressTransactionsExportCSVRequest,
  IAddressTransactionsRequest,
  IAddressTransactionsResponse,
  IAggregatedResponse,
  ITokenSummaryResponse,
  ITokenSummaryResult,
} from './dtos/address';
import { getNextPageParam, getTransactionTypeValue } from '../utils/helpers/helperFunctions';
import { getNextPageParamByOffsetTs } from '../utils/helpers/react-query.helper';

export class AddressLiteApi extends Api {
  readonly baseUrl: string = '/risk/api/v4';

  readonly getAddressLite = (id: string, currency: number) => {
    return this.http.get<IAddressResponse>(this.route(`${currency}/address-preview/${id}/`));
  };

  readonly getAddressTokenSummaryLite = (id: string, currency: number, offset: number) => {
    return this.http.get<ITokenSummaryResponse>(
      this.route(`${currency}/address-preview/${id}/token-summary/`),
      {
        params: { limit: 20, offset },
      }
    );
  };

  readonly getAddressMainBalanceLite = (id: string, currency: number) => {
    return this.http.get<ITokenSummaryResult>(this.route(`${currency}/address-preview/${id}/main-balance/`));
  };

  readonly getAggregatedInputsLite = (id: string, currency: number) => {
    return this.http.get<IAggregatedResponse>(
      this.route(`${currency}/address-preview/${id}/tag-aggregated-incoming-values/`)
    );
  };

  readonly getAggregatedOutputsLite = (id: string, currency: number) => {
    return this.http.get<IAggregatedResponse>(
      this.route(`${currency}/address-preview/${id}/tag-aggregated-outgoing-values/`)
    );
  };

  readonly getTransactionsLite = (params: IAddressTransactionsRequest) => {
    const { address, currency, filters, offset_ts } = params;
    const { type, ...restFilters } = filters;

    return this.http.get<IAddressTransactionsResponse>(
      this.route(`${currency}/address-preview/${address}/transactions/`),
      {
        params: { offset_ts, limit: 20, transaction_type: getTransactionTypeValue(type), ...restFilters },
      }
    );
  };

  readonly exportTransactionsCSVLite = (params: IAddressTransactionsExportCSVRequest) => {
    const { address, currency, filters } = params;
    const { type, ...restFilters } = filters;

    return this.http.get(this.route(`${currency}/address-preview/${address}/transactions/direct-export/`), {
      params: { transaction_type: getTransactionTypeValue(type), ...restFilters },
      responseType: 'blob',
    });
  };
}

export const addressLiteApi = new AddressLiteApi();

export const useAddressGetAddressLite = (id: string, currency: number, options?: UseQueryOptions) => {
  const key = ['addressLiteApi.getAddressLite', id];
  const result = useQuery(key, () => addressLiteApi.getAddressLite(id, currency), {
    ...options,
  } as unknown);

  return result;
};

export const useAddressGetAddressTokenSummaryLite = (
  id: string,
  currency: number,
  options?: UseQueryOptions
) => {
  const key = ['addressLiteApi.getAddressTokenSummaryLite', id];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => addressLiteApi.getAddressTokenSummaryLite(id, currency, pageParam),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );

  return result;
};

export const useAddressGetAddressMainBalanceLite = (
  id: string,
  currency: number,
  options?: UseQueryOptions
) => {
  const key = ['addressLiteApi.getAddressMainBalanceLite', id];
  const result = useQuery(key, () => addressLiteApi.getAddressMainBalanceLite(id, currency), {
    ...options,
  } as unknown);

  return result;
};

export const useAddressGetAggregatedInputsLite = (
  id: string,
  currency: number,
  options?: UseQueryOptions
) => {
  const key = ['addressLiteApi.getAggregatedInputsLite', id];
  const result = useQuery(key, () => addressLiteApi.getAggregatedInputsLite(id, currency), {
    ...options,
  } as unknown);

  return result;
};

export const useAddressGetAggregatedOutputsLite = (
  id: string,
  currency: number,
  options?: UseQueryOptions
) => {
  const key = ['addressLiteApi.getAggregatedOutputsLite', id];
  const result = useQuery(key, () => addressLiteApi.getAggregatedOutputsLite(id, currency), {
    ...options,
  } as unknown);

  return result;
};

export const useAddressGetTransactionsLite = (
  params: IAddressTransactionsRequest,
  options?: UseQueryOptions
) => {
  const key = ['addressLiteApi.getTransactionsLite', params.address, params.filters];
  const result = useInfiniteQuery(
    key,
    ({ pageParam }) => addressLiteApi.getTransactionsLite({ ...params, offset_ts: pageParam }),
    {
      ...options,
      getNextPageParam: getNextPageParamByOffsetTs,
    } as unknown
  );
  return result;
};

export const useAddressExportTransactionsCSVLite = (
  params: IAddressTransactionsExportCSVRequest,
  options?: UseQueryOptions
) => {
  const key = ['addressLiteApi.exportTransactionsCSVLite', params];
  const result = useQuery(key, () => addressLiteApi.exportTransactionsCSVLite(params), {
    ...options,
  } as unknown);

  return result;
};
