import { FC } from 'react';
import { useRouter } from '../../../modules/router/RouterProvider';
import { formatDateTime } from '../../../utils/helpers/date';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';
import EntityWithSubtypeBadge from '../../ui/components/EntityTypeBadge';
import CurrencyValue from '../../ui/components/Currency/CurrencyValue';
import ReportTable from '../ReportTable';
import { RiskBadge } from '../../../ui';
import { IAddressResponse } from '../../../api/dtos/address';

interface IAddressDetailsReportProps {
  addressData: IAddressResponse;
  balance: number;
  part?: number;
}

const AddressDetailsReport: FC<IAddressDetailsReportProps> = ({ addressData, balance, part }) => {
  const { getQueryParams } = useRouter();
  const timezone = getQueryParams().tz;
  const currentTime = formatDateTime(new Date().toLocaleString('en-US', { timeZone: timezone }));

  if (!addressData) return null;

  const tableData = [
    {
      label: 'Primary Digital Asset',
      value: (
        <CurrencyBadge currency={addressData.currency} label={addressData.currency_verbose} transparent />
      ),
    },
    ...(addressData.tag_name_verbose_owner && addressData.tag_type_verbose_owner
      ? [
          { label: 'Owner', value: addressData.tag_name_verbose_owner },
          {
            label: 'Owner Type',
            value: (
              <EntityWithSubtypeBadge
                type={addressData.tag_type_verbose_owner}
                subtype={addressData.tag_subtype_verbose_owner}
              />
            ),
          },
        ]
      : []),
    ...(addressData.tag_name_verbose && addressData.tag_type_verbose
      ? [
          { label: 'User', value: addressData.tag_name_verbose },
          {
            label: 'User Type',
            value: (
              <EntityWithSubtypeBadge
                type={addressData.tag_type_verbose}
                subtype={addressData.tag_subtype_verbose}
              />
            ),
          },
        ]
      : []),
    {
      label: 'Digital Assets List',
      value: [
        {
          label: 'Name',
          value: (
            <CurrencyBadge currency={addressData.currency} label={addressData.currency_verbose} transparent />
          ),
        },
        {
          label: 'Balance',
          value: <CurrencyValue value={balance} currency={addressData.currency_short} />,
        },
      ],
    },
    {
      label: 'Risk Level',
      value: (
        <div>
          <RiskBadge risk={addressData.risk_level} />
        </div>
      ),
    },
    {
      label: 'First Transaction Time',
      value: (
        <span className='my-auto'>{formatDateTime(addressData.earliest_transaction_time, timezone)}</span>
      ),
    },
    {
      label: 'Latest Transaction Time',
      value: <span className='my-auto'>{formatDateTime(addressData.latest_transaction_time, timezone)}</span>,
    },
  ];

  return (
    <div>
      <h1 className='mb-1 text-[26px] font-extrabold'>Address Report</h1>
      <code className='lead mb-3'>{addressData.identifier}</code>
      <hr className='my-2' />

      <div className='text-2xs'>
        <span className='mr-5 inline-block text-gray-500'>Report Exported On</span>
        {currentTime}
      </div>
      <hr className='my-2' />

      <ReportTable title={`Part ${part}: Address Details`} tableData={tableData} />
    </div>
  );
};

export default AddressDetailsReport;
