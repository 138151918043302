import * as React from 'react';

import { BasicBadge, Checkbox } from '../../../ui';
import CalendarFilter, { IDateRangeString } from '../../../components/Filters/CalendarFilter';
import RiskFilter from '../../../components/Filters/RiskFilter';
import CurrencyFilter from '../../../components/Filters/CurrencyFilter';
import RuleNameFilter from '../../../components/Filters/RuleNameFilter';
import { FilterPanel } from '../../../components/FilterPanel';
import SingleSelect from '../../../components/ui/components/Select/SingleSelect';
import EntityFilter from '../../../components/Filters/EntityFilter';
import CustomSelect from '../../../components/Filters/CustomSelect';
import { ellipsis } from '../../../utils/helpers/helperFunctions';
import StageNameFilter from '../../../components/Filters/StageFilter';
import CustomTagsFilter from '../../../components/Filters/CustomTagsFilter';

export interface IOption {
  value: string;
  label: string;
}

export interface IFilters {
  tag_type_verboses: string[];
  tag_name_verboses: string[];
  risk_level: number[];
  currency: number[];
  created_start_date: string;
  created_end_date: string;
  start_date: string;
  end_date: string;
  is_included_in_case: boolean;
  rule_name: string[];
  workflow_stage?: (number | string)[];
  custom_tags: (number | string)[];
}

export const defaultAddressListFilters: IFilters = {
  tag_type_verboses: [],
  tag_name_verboses: [],
  risk_level: [],
  currency: [],
  created_start_date: null,
  created_end_date: null,
  start_date: null,
  end_date: null,
  is_included_in_case: null,
  rule_name: [],
  workflow_stage: [],
  custom_tags: [],
};

interface Props {
  filters: IFilters;
  entityNameVerboseOptions: IOption[];
  onApply: (any) => void;
  onReset: () => void;
  disabled?: boolean;
  tab: number;
  customTagsOptions: IOption[];
}

const filterOptions = [
  { label: 'Entity Type', value: '0' },
  { label: 'Entity Name', value: '1' },
];

const AddressListFilter: React.FC<Props> = ({
  filters,
  onApply,
  entityNameVerboseOptions,
  disabled,
  tab,
  customTagsOptions,
}) => {
  const [entityFilterType, setEntityFilterType] = React.useState<IOption>(filterOptions[0]);
  const [tagTypeVerboses, setTagTypeVerboses] = React.useState<string[]>([]);
  const [tagNameVerboses, setTagNameVerboses] = React.useState<string[]>([]);
  const [riskLevel, setRiskLevel] = React.useState<number[]>([]);
  const [dateAdded, setDateAdded] = React.useState<IDateRangeString>();
  const [dateUpdated, setDateUpdated] = React.useState<IDateRangeString>();
  const [currency, setCurrency] = React.useState<number[]>([]);
  const [includedInCase, setIncludedInCase] = React.useState<boolean>(null);
  const [ruleName, setRuleName] = React.useState<string[]>([]);
  const [workflow_stage, setStage] = React.useState<(number | string)[]>([]);
  const [customTags, setCustomTags] = React.useState<(number | string)[]>([]);

  const handleApply = () => {
    const newFilters: IFilters = {
      tag_type_verboses: tagTypeVerboses,
      tag_name_verboses: tagNameVerboses,
      risk_level: riskLevel,
      currency: currency,
      created_start_date: dateAdded.from,
      created_end_date: dateAdded.to,
      start_date: dateUpdated.from,
      end_date: dateUpdated.to,
      is_included_in_case: includedInCase,
      rule_name: ruleName,
      workflow_stage: workflow_stage,
      custom_tags: customTags,
    };
    onApply(newFilters);
  };

  const onReset = () => {
    setTagTypeVerboses([]);
    setTagNameVerboses([]);
    setRiskLevel([]);
    setDateAdded(null);
    setDateUpdated(null);
    setCurrency([]);
    setIncludedInCase(null);
    setRuleName([]);
    setStage([]);
    setCustomTags([]);
    onApply(defaultAddressListFilters);
  };

  React.useEffect(() => {
    if (filters.tag_type_verboses?.length) {
      setEntityFilterType(filterOptions[0]);
    } else {
      setEntityFilterType(filterOptions[1]);
    }
    setTagTypeVerboses(filters.tag_type_verboses);
    setTagNameVerboses(filters.tag_name_verboses);
    setRiskLevel(filters.risk_level);
    setCurrency(filters.currency);
    setDateAdded({
      from: filters.created_start_date,
      to: filters.created_end_date,
    });
    setDateUpdated({
      from: filters.start_date,
      to: filters.end_date,
    });
    setIncludedInCase(filters.is_included_in_case);
    setRuleName(filters.rule_name);
    setStage(filters.workflow_stage);
    setCustomTags(filters.custom_tags);
  }, [filters]);

  const handleFilterTypeChange = (option: IOption) => {
    setEntityFilterType(option);

    if (option.value === '0') {
      setTagNameVerboses([]);
    } else {
      setTagTypeVerboses([]);
    }
  };

  const handleChange = (entities: IOption[]) => {
    const entityNames = entities?.map((entity) => entity.value) ?? [];
    setTagNameVerboses(entityNames);
  };

  return (
    <FilterPanel onApply={handleApply} onReset={onReset} disabled={disabled} enableFilterScroll>
      <div />
      <div>
        <label className='text-sm font-medium text-gray-500'>{'User / Owner'}</label>
        <div className='flex w-full'>
          <div className='w-2/5'>
            <SingleSelect
              value={entityFilterType}
              options={filterOptions}
              handleChange={handleFilterTypeChange}
              className='mt-1 rounded-md rounded-r-none border border-r-[0.5px] border-gray-300'
              isCloseHidden
              noBorder
            />
          </div>
          <div className='w-3/5'>
            {entityFilterType.value === '0' ? (
              <EntityFilter
                value={tagTypeVerboses}
                setValue={setTagTypeVerboses}
                className='mt-1 rounded-md rounded-l-none border border-l-0 border-gray-300'
                limit={1}
                noLabel
                noBorder
              />
            ) : (
              <CustomSelect
                limit={1}
                placeholder='Select Entities'
                getCustomLabel={(entity) => <BasicBadge>{ellipsis(entity.label, 25, true)}</BasicBadge>}
                value={tagNameVerboses?.map((name) => ({ label: name, value: name })) ?? []}
                options={entityNameVerboseOptions}
                handleChange={handleChange}
                className='mt-1 rounded-md rounded-l-none border border-l-0 border-gray-300'
                noBorder
              />
            )}
          </div>
        </div>
      </div>
      <RiskFilter value={riskLevel} setValue={setRiskLevel} />
      {tab !== 2 && <StageNameFilter is_status_stage={false} value={workflow_stage} setValue={setStage} />}
      <CurrencyFilter value={currency} setValue={setCurrency} />
      <CalendarFilter value={dateAdded} setValue={setDateAdded} label='Date Added' />
      <CalendarFilter value={dateUpdated} setValue={setDateUpdated} label='Date Updated' />
      <RuleNameFilter value={ruleName} setValue={setRuleName} type={'address'} />
      <CustomTagsFilter value={customTags} setValue={setCustomTags} customTagsOptions={customTagsOptions} />
      <Checkbox
        checked={includedInCase}
        labelText={<span className='text-sm font-medium leading-5 text-gray-700'>Included in Case</span>}
        onChange={(e) => setIncludedInCase((e.target as HTMLInputElement).checked)}
      />
    </FilterPanel>
  );
};

export default AddressListFilter;
