import { FC, useEffect, useState } from 'react';
import { FilterPanel } from '../../../FilterPanel';
import RiskFilter from '../../../Filters/RiskFilter';
import RuleNameFilter from '../../../Filters/RuleNameFilter';
import { IAlertFiltersUpdate } from '../../../../api/dtos/alert';
import RiskTypeFilter from '../../../Filters/RiskTypeFilter';
import RuleConditionFilter from '../../../Filters/RuleConditionFilter';
import CalendarFilter, { IDateRangeString } from '../../../Filters/CalendarFilter';
import StageNameFilter from '../../../../components/Filters/StageFilter';
import AssignedToFilter from '../../../../components/Filters/AssignedToFilter';
interface AlertFilterProps {
  filters?: IAlertFiltersUpdate;
  onApply: (filters: IAlertFiltersUpdate) => void;
  type: 'address' | 'transaction' | 'customer';
  disabled?: boolean;
}

export const defaultStateFilters: IAlertFiltersUpdate = {
  level: [],
  rule_name: [],
  risk_type: [],
  rule_condition_type: [],
  transaction_start_date: '',
  transaction_end_date: '',
  status: [],
  assigned_to: [],
  assigned_to_name: [],
};

const AlertFilter: FC<AlertFilterProps> = ({ filters, onApply, type, disabled = false }) => {
  const [level, setLevel] = useState<number[]>([]);
  const [ruleName, setRuleName] = useState<string[]>([]);
  const [riskType, setRiskType] = useState<number[]>([]);
  const [ruleConditionType, setRuleConditionType] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<IDateRangeString>();
  const [workflow_stage, setStage] = useState<(number | string)[]>([]);
  const [assignedTo, setAssignedTo] = useState<(number | string)[]>([]);
  const [assigneesList, setAssignees] = useState<string[]>([]);

  const handleApply = () => {
    const newFilters = {
      level,
      rule_name: ruleName,
      risk_type: riskType,
      rule_condition_type: ruleConditionType,
      transaction_start_date: dateRange?.from,
      transaction_end_date: dateRange?.to,
      status: workflow_stage,
      assigned_to: assignedTo,
      assigned_to_name: assigneesList,
    };
    onApply(newFilters);
  };

  const onReset = () => {
    setLevel([]);
    setRuleName([]);
    setRiskType([]);
    setRuleConditionType([]);
    setDateRange(null);
    setStage([]);
    setAssignedTo([]);
    setAssignees([]);
    onApply(defaultStateFilters);
  };

  useEffect(() => {
    setLevel(filters.level);
    setRuleName(filters.rule_name);
    setRiskType(filters.risk_type);
    setRuleConditionType(filters.rule_condition_type);
    setDateRange({
      from: filters.transaction_start_date,
      to: filters.transaction_end_date,
    });
    setStage(filters.status);
    setAssignedTo(filters.assigned_to);
    setAssignees(filters.assigned_to_name);
  }, [filters]);

  return (
    <FilterPanel onApply={handleApply} onReset={onReset} disabled={disabled} enableFilterScroll>
      <RiskFilter value={level} setValue={setLevel} levels={[5, 4, 3, 2, 1]} />
      <StageNameFilter is_status_stage value={workflow_stage} setValue={setStage} />
      <AssignedToFilter
        assigneesList={assigneesList}
        setAssignees={setAssignees}
        value={assignedTo}
        setValue={setAssignedTo}
      />
      <RuleNameFilter value={ruleName} setValue={setRuleName} type={type} />
      <RiskTypeFilter value={riskType} setValue={setRiskType} />
      <RuleConditionFilter value={ruleConditionType} setValue={setRuleConditionType} />
      {type === 'customer' && (
        <CalendarFilter value={dateRange} setValue={setDateRange} label='Transaction Date' />
      )}
    </FilterPanel>
  );
};

export default AlertFilter;
