import { FC, useEffect, useState } from 'react';
import { FilterPanel } from '../FilterPanel';
import SingleSelect from '../ui/components/Select/SingleSelect';
import RiskFilter from '../Filters/RiskFilter';
import { policyTypeOptions } from '../../utils/constants/rules';
interface RuleListFilterProps {
  filters?: IFilters;
  onApply: (filters: IFilters) => void;
}

export interface IFilters {
  ruleStatus: string;
  riskLevel: number[];
  policyStatus: string;
  policyType: string;
}

export const defaultStateFilters: IFilters = {
  ruleStatus: 'live',
  riskLevel: [],
  policyStatus: 'live',
  policyType: '',
};

const RuleListFilter: FC<RuleListFilterProps> = ({ filters, onApply }) => {
  const [ruleStatus, setRuleStatus] = useState<string>('');
  const [riskLevel, setRiskLevel] = useState<number[]>([]);
  const [policyStatus, setPolicyStatus] = useState<string>('');
  const [policyType, setPolicyType] = useState<string>('');

  const handleApply = () => {
    const newFilters = {
      ruleStatus,
      riskLevel,
      policyStatus,
      policyType,
    };
    onApply(newFilters);
  };

  const onReset = () => {
    setRuleStatus('');
    setRiskLevel([]);
    setPolicyStatus('');
    setPolicyType('');
    onApply(defaultStateFilters);
  };

  useEffect(() => {
    setRuleStatus(filters.ruleStatus);
    setRiskLevel(filters.riskLevel);
    setPolicyStatus(filters.policyStatus);
    setPolicyType(filters.policyType);
  }, [filters]);

  const ruleStatusOptions = [
    { label: 'Live', value: 'live' },
    { label: 'Deactivated', value: 'deactivated' },
    { label: 'All', value: '' },
  ];

  const policyStatusOptions = [
    { label: 'Live', value: 'live' },
    { label: 'Deactivated', value: 'deactivated' },
    { label: 'All', value: '' },
  ];

  return (
    <FilterPanel onApply={handleApply} onReset={onReset} enableFilterScroll>
      <SingleSelect
        options={ruleStatusOptions}
        handleChange={(value) => setRuleStatus(value?.value)}
        value={ruleStatusOptions.find((option) => option.value === ruleStatus)}
        label='Rule Status'
        labelClassName='text-sm'
      />
      <RiskFilter value={riskLevel} setValue={setRiskLevel} />
      <SingleSelect
        options={policyStatusOptions}
        handleChange={(value) => setPolicyStatus(value?.value)}
        value={policyStatusOptions.find((option) => option.value === policyStatus)}
        label='Policy Status'
        labelClassName='text-sm'
      />
      <SingleSelect
        options={policyTypeOptions}
        handleChange={(value) => setPolicyType(value?.value)}
        value={policyTypeOptions.find((option) => option.value === policyType)}
        label='Policy Type'
        labelClassName='text-sm'
      />
    </FilterPanel>
  );
};

export default RuleListFilter;
