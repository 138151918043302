import * as React from 'react';
import { useAddressGetAddress, useAddressGetMainBalance } from '../../../api/address';
import AssociatedCases from '../../../components/AddressTransaction/AssociatedCases';
import AddressTransactions from '../../../components/Address/AddressTransactions';
import CompassLoader from '../../../components/ui/components/Loader/CompassLoader';
import DetailPage from '../../../layouts/DetailPage';
import AddressDetailsMetadata from '../../../components/Address/AddressMetadata';
import Tab, { ITabValue } from '../../../components/ui/components/Tab/Tab';
import { useRouter } from '../../../modules/router/RouterProvider';
import Overview from '../../../components/Address/Overview/Overview';
import { useQuery } from 'react-query';
import { useUpdateEffect } from 'react-use';
import { authApi } from '../../../api/auth';
import { useAuth } from '../../../modules/auth';
import { isLiteCurrency } from '../../../utils/helpers/currency';
import OverviewLightChains from '../../../components/Address/Overview/OverviewLightChains';
import AuditLogs from '../../../components/common/AuditLogs';
import classNames from 'classnames';

interface AddressDetailsProps {
  id?: string;
}

const AddressDetails: React.FC<AddressDetailsProps> = ({ id }) => {
  const { getParams, getQueryParams } = useRouter();
  const addressId = getParams().identifier || getParams().addressId;
  const alertId = getQueryParams().alert;
  const { setUser } = useAuth();
  const { data: permissions } = useQuery(['userProfile', addressId], () => authApi.getPermissions());

  useUpdateEffect(() => {
    if (permissions?.data?.first_name != null) {
      setUser({
        userProfile: permissions.data,
      });
    }
  }, [permissions, setUser]);

  const { data: response, isLoading: detailLoading } = useAddressGetAddress(Number(id || addressId), {
    useErrorBoundary: true,
  });
  const addressMainBalance = useAddressGetMainBalance(response?.data?.id, {
    enabled: ![undefined, null].includes(response?.data?.id),
  });

  const [alerts, setAlerts] = React.useState(0);
  const [alertDetails, setAlertDetails] = React.useState<number>(null);

  React.useEffect(() => {
    if (alertId) {
      setAlertDetails(Number(alertId));
    }
  }, [alertId, setAlertDetails]);

  if (detailLoading) {
    return <CompassLoader />;
  }

  const tabs: ITabValue[] = [
    { label: 'Overview' },
    { label: 'Transactions' },
    {
      label: 'Associated Cases',
      count: response?.data?.associated_cases?.length,
      hidden: !response?.data?.associated_cases?.length,
    },
    {
      label: 'Audit Logs',
    },
  ];

  return (
    <DetailPage alertDetails={alertDetails}>
      <AddressDetailsMetadata
        address={response.data}
        alerts={alerts}
        balance={addressMainBalance?.data?.data?.balance}
      />
      <div className=''>
        {!isLiteCurrency(response?.data?.currency) ? (
          <Tab
            values={tabs}
            type='secondary'
            listClassName={classNames('border-b border-gray-200 py-2 px-4 sticky z-30 top-0', {
              hidden: !!alertDetails,
            })}
            panelClassName='p-4'>
            <Overview
              address={response.data}
              setAlerts={setAlerts}
              alertDetails={alertDetails}
              setAlertDetails={setAlertDetails}
              balance={addressMainBalance?.data?.data?.balance}
              balance_usd={addressMainBalance?.data?.data?.balance_usd}
            />
            <AddressTransactions address={response.data} />
            <AssociatedCases associatedCases={response.data.associated_cases} />
            <AuditLogs type='address' id={response.data.id} />
          </Tab>
        ) : (
          <OverviewLightChains address={response.data} setAlerts={setAlerts} alertDetails={alertDetails} />
        )}
      </div>
    </DetailPage>
  );
};

export default AddressDetails;
